<template>
	<v-container>
		<div>
			<s-crud
				:transfer="!disableButtonSampling "
				@transfer="transfer()"
				title="Lotes Abastecidos"
				:filter="filter"
				:config="config"
				no-full
				search-input
				ref="LotsSupplying"
				height="auto"
				:group-by="'RcfIDName'"
				@rowSelected="rowSelected($event)"
				:single-row="false"
			>
				<template v-slot:IsTransfer="{ row }">
					<v-chip x-small :color="row.IsTransfer == 0  ? 'warning': 'info'">
						{{ row.IsTransfer == 0  ? 'No': 'Si' }}
					</v-chip>
				</template>

				<template v-slot:Actions="{ row }">
					<v-tooltip top color="primary">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								@click="removeSupplyng(row)"
								dark
								x-small
								color="transparent"
								elevation="0"
								v-bind="attrs"
								v-on="on"	
							>
								<v-icon
									color="error"
									class="fas fa-sign-out-alt"
								>
								</v-icon>
							</v-btn>
						</template>
						<span>Desabastecer</span>
					</v-tooltip>
				</template>

				<template v-slot:xCount="{ row }">
						<v-chip
							x-small
							:color="row.xCount > 0 ? 'error' : 'info'"
						>
							{{row.xCount > 0 ? 'Usado Empaque y/o Re-empaque' : 'Libre'}}
						</v-chip>
				</template>

				<!-- filtros en el scrud por fechas -->
				<template v-slot:filter>
					<v-container>
						<v-row justify="center">

							<v-col cols="12" md="4" lg="3">
								<s-select-definition
									label="Destino"
									:def="1181"
									v-model="filter.TypeDestiny"
								>
								</s-select-definition>
							</v-col>

							<v-col cols="12" md="4" lg="3">
								<s-date label="Fecha Inicio" v-model="filter.BeginDate">
								</s-date>
							</v-col>

							<v-col cols="12" md="4" lg="3">
								<s-date v-model="filter.EndDate" label="Fecha Fin">
								</s-date>
							</v-col>

						</v-row>

						<v-row justify="center">
							<v-col cols="6" md="4" lg="3">
								<s-select-definition
									clearable
									:def="1172"
									label="Tipo Cultivo"
									v-model="filter.TypeCrop"
								/>
							</v-col>
							<v-col cols="6" md="4" lg="3">
								<s-select-definition
									clearable
									:def="1173"
									label="Cultivo"
									v-model="filter.TypeCultive"
								/>
							</v-col>

							<v-col cols="12" md="4" lg="3">
								<s-select-variety
									clearable
									:cultiveID="filter.TypeCultive"
									label="Variedad"
									full
									v-model="filter.VrtID"
								/>
							</v-col>
						</v-row>
					</v-container>
				</template>
			</s-crud>
		</div>

		<v-dialog persistent v-model="openDerive" v-if="openDerive" width="500">
			<v-card>
				<v-col>
					<v-row>
						<h2 class="mt-6 ml-3">Derivar a congelado - Empaque</h2>
						<v-spacer></v-spacer>
						<v-btn
							class="mt-3 mr-3"
							@click="openDerive = false"
							small
							fab
							color="error"
							><i style="font-size:16px;" class="fas fa-times"></i
						></v-btn>
					</v-row>
					<v-row>
						<v-col>
							<s-text
								label="Transpaso de"
								v-model="lotPackingData.FltName"
								readonly
							></s-text>
						</v-col>
						<v-col>
							<s-date
								label="Fecha"
								v-model="lotProduction.TfhDateGuide"
							></s-date>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<s-text label="N° Guia interna" v-model="lotProduction.LdhNumberGuideInternal"></s-text>
						</v-col>

						<!-- <v-col cols="6">
							<s-text label="Fecha" v-model="lotProduction.LdhNumberGuideInternal"></s-text>
						</v-col> -->

						<v-col cols="6">
							<s-text label="Cant. Jabas" number v-model="lotProduction.LdhQuantityJabas"> </s-text>
						</v-col>

						<v-col cols="6">
							<s-text label="Peso Descarte" decimal v-model="lotProduction.LdhDiscartTotal"> </s-text>
						</v-col>
					</v-row>
					<v-col>
						<v-row justify="center" class="pt-3">
							<v-spacer></v-spacer>
							<v-btn class="ml-3" dark color="primary" @click="sendFrozen()"
								>Transferir</v-btn
							>
						</v-row>
					</v-col>
				</v-col>
			</v-card>
		</v-dialog>

	</v-container>
</template>
<script>
	import _sSupplying from "../../../services/FreshProduction/SupplyingService";
	import _sSupplyingDetail from "../../../services/FreshProduction/SupplyingDetailService";
	import _sFarmLot from '@/services/Technicalassistance/FarmLotService';
	import _sDiscartDamage from "@/services/FreshProduction/PrfLotDiscartDamageService.js";
	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";

	export default {
		components: {
			SSelectVariety,
		},
		data: () => ({
			disableButtonSampling: true,
			SppIdsArray : [],
			palletsSelection : [],
			SppIdsArrayString : "",
			DataSupplyingDetail: [],
			lotProduction : {},
			lotPackingData: {},
			openDerive: false,
			itemDest:null,
			/* caliberFil: "", */
			filter: {
				BeginDate: "",
				EndDate: "",
				TypeDestiny: 0,
				TypeCrop : 0 ,
				TypeCultive : 0,
				VrtID : 0
				/* caliberFilter: 0, */
			},
			config: {
				service: _sSupplyingDetail,
				model: {
					SppID: "ID",
					Actions: "",
					SecCreate: "datetime",
					xCount: "",
					IsTransfer: ""
				},
				headers: [
					{ text: "Accion", value: "Actions", width: 100 },
					{ text: "Transferido", value: "IsTransfer", width: 100 },
					{ text: "Cod. Abast.", value: "SppID", width: 100 },
					{ text: "Tarima", value: "ScfCode", width: 100 },
					{ text: "Parihuela.", value: "LlpID", width: 100 },
					{ text: "Lote Producción.", value: "RcfIDName", width: 100 },
					{ text: "Calibre", value: "TypeCaliberName", width: 100 },
					{ text: "Cant. jabas", value: "SpdQuantityJaba", width: 100 },
					{ text: "Ubicación", value: "TypeZonLocationName", width: 100},
					{ text: "Tipo cultivo", value: "TypeCropName", width: 150 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 100 },
					{ text: "Variedad", value: "VrtDescription", width: 100 },
					{ text: "Fec. Abast.", value: "SecCreate", width: 150 },
					{ text: "Coment.", value: "xCount", width: 150 },
					
				],
			},
		}),

		methods: {

			sendFrozen()
			{
				this.SppIdsArrayString = this.SppIdsArray.join(",");
				if(this.lotProduction.LdhNumberGuideInternal.length == 0 || this.lotProduction.LdhNumberGuideInternal == null){
					this.$fun.alert(
						"Error, Ingrese numero de guia interna",
						"warning",
					);
					return;
				}

				if(this.lotProduction.LdhQuantityJabas <= 0){
					this.$fun.alert("Error, Ingrese una cantidad de jabas", "warning")
					return
				}

				if(this.lotProduction.LdhDiscartTotal <= 0){
					this.$fun.alert("Error, Ingrese un Peso de Guia", "warning")
					return
				}

				this.lotProduction.LdhGrossWeight = this.lotProduction.LdhDiscartTotal
				this.lotProduction.UsrCreateID = this.$fun.getUserID();
				this.lotProduction.xState = 0; //this.filter.TypeDestiny == 1 ? 2 : 3;
				this.lotProduction.Rcfids = this.SppIdsArrayString;
				this.lotProduction.RcfID = 1;
				this.lotProduction.TypeDestiny = this.filter.TypeDestiny;

				this.$fun.alert("¿Seguro de transferir Lote descarte a congelado?", "question").then(val => {
					if(val.value){
						console.log('datos a guardar ', this.lotProduction);
						_sDiscartDamage.transferDamage(this.lotProduction, this.$fun.getUserID()).then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Transferido correctamente", "success");
								this.openDerive = false
								//this.$refs.crudLotDiscart.refresh();
                                this.$refs.LotsSupplying.refresh();
							}
						})
						
					}
				})
			},
			transfer(){
				this.getFarmLotTransfer();
				console.log('los obtenidos ', this.palletsSelection);
				console.log('los obtenidos ', this.SppIdsArray);
				var countError = 0;
				this.palletsSelection.forEach(element => {
					if(element.IsTransfer == 1){
						this.$fun.alert("Advertencia, registro seleccionado, ya ha sido transferido => " + element.SppID, "warning");
						countError ++;
					}
				})
				if(countError == 0)
				{
					this.openDerive = true;
				}
				
			},

			rowSelected(item) {
				this.SppIdsArray = []
				if(item.length > 0){
					if(item !== undefined){
						this.palletsSelection = [];
						this.disableButtonSampling = false;
						item.forEach(element => {
							this.palletsSelection.push(element);
							if(element.IsTransfer == 0)
							{
								this.SppIdsArray.push(element.SppID);
							}
						})
					}
				}
			},

			getFarmLotTransfer()
			{
				//var destiny = 0;
				//this.filter.TypeDestiny == 1 ? destiny = 2 : destiny = 3
				let obj = {
					OriginTransfer : this.filter.TypeDestiny 
				}
				_sFarmLot
				.searchlottransfer(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						console.log('resp.data ', resp.data);
						this.lotPackingData = resp.data;
					}
				})
			},

			removeSupplyng(item) {

				
				item.SpdID = item.SppID
				item.SecStatus = 0;
				item.UsrCreateID = this.$fun.getUserID()

				/* let DataHead = {};
				this.DataSupplyingDetail.push(item); */

				/* DataHead.SppID = item.SppID;
				DataHead.LppID = item.LppID;
				DataHead.SppQuantityJaba = item.SpdQuantityJaba;
				DataHead.SecStatus = 1;
				DataHead.TypeDestiny = item.TypeDestiny;
				DataHead.SppState = 1; */

				/* DataHead.DataSupplyingDetail = this.DataSupplyingDetail; */

				console.log("desabastecer",item);

				this.$fun.alert("Seguro que desea desabastecer ?", "question").then(r => {
					if(r.value){
						/* _sSupplying.save(item, this.$fun.getUserID()).then(r => {
							if (r.status == 200) {
								this.$fun.alert("Desabastecido correctamente", "success");
								this.$refs.LotsSupplying.refresh();
							}
						}); */
					}	

				});
				
			},
		},

	};
</script>
